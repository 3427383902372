
@media (min-width: 1024px) {
    .advance-equipment{
        padding-left: 0px;
        padding-right: 0px;    }
}

@media (min-width: 768px) {
    .advance-equipment{
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }
}

