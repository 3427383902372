.image-skew-left {    
  
    /* -webkit-transform: perspective(200px) rotateX(-5deg);
    -webkit-transform-origin: left center;
    -moz-transform: perspective(200px) rotateX(-5deg);
    -moz-transform-origin: left center; */
    clip-path: polygon(0 0, 100% 0, 90% 100%, 0% 100%);

   
}

@media (max-width: 768px) {
    .image-skew-left {    
  
        /* -webkit-transform: perspective(200px) rotateX(-20deg);
        -webkit-transform-origin: left center;
        -moz-transform: perspective(200px) rotateX(-5deg);
        -moz-transform-origin: left center; */
        clip-path: polygon(0 0, 100% 0, 80% 100%, 0% 100%);

       
    }
}

.image-skew-right {    
/*   
    -webkit-transform: perspective(200px) rotateX(5deg);
    -webkit-transform-origin: right center;
    -moz-transform: perspective(200px) rotateX(5deg);
    -moz-transform-origin: right center; */
    clip-path: polygon(10% 0, 100% 0, 100% 100%, 0% 100%);

}

@media (max-width: 768px) {
    .image-skew-right {    
  
        /* -webkit-transform: perspective(200px) rotateX(20deg);
        -webkit-transform-origin: right center;
        -moz-transform: perspective(200px) rotateX(5deg);
        -moz-transform-origin: right center; */
        clip-path: polygon(20% 0, 100% 0, 100% 100%, 0% 100%);

    }
    
}
