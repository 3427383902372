.answerImg--vertical {
  width: 260px;
  height: 400px;
  /* max-width: 600px; */
}

.answerImg--vertical-extended {
  width: 420px;
  height: 600px;
  /* max-width: 600px; */
}

.qa-detail {
  display: flex;
  flex-direction: row;
}

.qa-paragraph {
  margin-left: 10px;
}

.qa-paragraph>p:not(:last-child) {
  margin-bottom: 1.25rem;
}

.answerImg--horizontal {
  width: 500px;
  height: 300px;
}

@media only screen and (max-width: 599px) {
  .qa-detail {
    flex-direction: column;
  }

  .answerImg--vertical {
    margin: auto;
    margin-bottom: 1.25rem;
  }
}