@media (min-width: 768px){
      .parent{
    display: flex;
    flex-wrap: wrap;
  }
  
  .child{
    width: 34%;
    box-sizing: border-box;
  }
}



  