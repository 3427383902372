.footer {
    background-size: contain;
    background-position: bottom ;

}

@media (max-width: 767px) {
    .footer {
        background-size: contain;
        background-position: bottom ;
        background-repeat: no-repeat;
    }
}