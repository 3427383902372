.header {
    background-position: top;
    background-size: contain;
    background-color: black;
    justify-content: space-between;
}

.border-transition:after {
    display:block;
    content: '';
    padding-bottom: 7px;
    border-bottom: solid 3px #C4C4C4;  
    transform: scaleX(0);  
    transition: transform 300ms ease-in-out;
  }

.border-transition:hover:after { transform: scaleX(1); }
